import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "../styles/index.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

import Menu from "../pages/menu";
import Header from "../pages/header";
import RightColumn from "../pages/rightcolumn";

function Index() {
  const [date, setDate] = useState(null);
  useEffect(() => {
    async function getDate() {
      const res = await fetch("/api/date");
      const newDate = await res.text();
      setDate(newDate);
    }
    getDate();
  }, []);

  return (
    <main>
      <Helmet>
        <title>Homophone</title>
      </Helmet>
      <Container fluid>
        <Row>
          <Col lg={1} md={1} xs={1}>
            <Menu />
          </Col>
          <Col lg={1} md={1} xs={1}></Col>
          <Col lg={8} md={8} xs={8}>
            <Header />
            <p className="fontrem20">homophone</p>
          </Col>
          <Col lg={2} md={2} xs={2}>
          <RightColumn index = "3"/>
          </Col>
        </Row>
      </Container>
    </main>
  );
}

export default Index;
